/* src/components/TopNavbar.css */

/* Desktop navbar (visible by default) */
.top-navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #000;
  padding: 10px 20px;
  font-family: sans-serif;
  font-weight: bold;
  white-space: nowrap;
  margin-bottom: 15px;
}

.nav-item {
  color: #808080;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  margin: 0 10px;
}

.nav-item.selected {
  color: white;
}

/* Hide entire navbar on phone */
@media (max-width: 768px) {
  .top-navbar {
    display: none;
  }
}