/* src/components/Sidebar.css */
* {
    box-sizing: border-box;
  }
  
  .sidebar-wrapper {
    color: #fff;
    height: 70vh;  /* fixed height for the sidebar on desktop */
    display: flex;
    flex-direction: column;
  }
  
  .sidebar-header {
    padding: 10px 0 0px 0; 
    flex-shrink: 0;
  }
  
  .sidebar-search {
    width: 100%;
    padding: 8px 20px 2px 0px;
    border: none;
    border-bottom: 1px solid white;
    background: transparent url('../assets/navSearch.svg') no-repeat right center;
    background-size: 16px;
    color: #3a3a3a;
    font-weight: lighter;
    font-family: Helvetica, sans-serif;
    font-size: 9px;
    margin-bottom: 5px;
  }
  .sidebar-search:focus {
    outline: none;
  }
  
  .sidebar-list {
    list-style: none;
    margin: 0;
    padding: 0;
    flex: 1;
    overflow-y: auto;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }
  .sidebar-list::-webkit-scrollbar {
    display: none; 
  }
  
  .sidebar-item {
    list-style: none;
    font-family: Helvetica, sans-serif;
    font-size: 9px;
    letter-spacing: 0px;
    font-weight: lighter;
    padding: 0;
    margin: 0;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    pointer-events: auto;
    cursor: pointer;
    color: white;
    opacity: 0.6;
    transition: opacity 0.25s ease;
    transform-origin: center;
    position: relative;
  }
  .sidebar-item:hover {
    transition: opacity 0s;
    opacity: 1;
    z-index: 1;
  }
  
  .logo-container {
    margin-bottom: 10px;
    padding: 0;
  }
  
  .logo {
    height: auto;
    width: 135px;
    display: block;
    opacity: 1;
  }
  
  /* 
     At phone size, .left-column is hidden by default from App.css,
     so if you wanted a phone “menu” approach, you'd define it differently. 
     But we’re letting .left-column get hidden entirely. 
  */