/* src/components/PhoneMenu.css */

/* By default, hidden on desktop. We only show on phone when toggled. */
.phone-menu-overlay {
    display: none;
  }
  
  @media (max-width: 768px) {
    .phone-menu-overlay {
      /* Cover the whole screen area behind the phone header. */
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: black;
      color: white;
      z-index: 9999; /* Just below the header's 10000 */
  
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
  
    /* Because the header is at top with z-index:10000,
       this pushes our menu content down so it doesn't hide behind the header.
       Adjust this 80px to match your header's height. */
    .phone-menu-inner {
      margin-top: 130px; 
      flex: 1;
      overflow-y: auto;
      padding: 0 0px 20px 0px;
      box-sizing: border-box;
    }
    .phone-menu-search-container {
      /* Possibly smaller spacing if you prefer */
      margin: 0 0 0 0;
    }
  
    .phone-menu-search {
      width: 100%;
      border: none;
      border-bottom: 0.5px solid silver;
      background: transparent url('../assets/navSearch.svg') no-repeat 10px center; /* More indented from the left */
      background-size: 30px; /* Increased size from 16px to 24px */
      color: white;
      font-size: 22px;
      font-weight: 300;
      text-transform: uppercase;
      padding: 15px 0 15px 45px; /* Increased left padding for more indentation */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      opacity: 0.6;
    }
    .phone-menu-search::placeholder {
      color: #aaa;
      opacity: 0.6;

    }
    
    .phone-menu-search:focus {
      outline: none;
    }
  
    .phone-menu-links {
      list-style: none;
      margin: 0;
      padding: 0;
      
    }
    .phone-menu-links li {
      font-size: 20px;
      font-weight: bold;
      padding: 12px 20px 12px 20px;
      cursor: pointer;
      text-transform: uppercase;
      border-bottom: 0.5px solid silver;
      font-weight: 300;
      margin-left: 20px ;
      opacity: 0.6;
    

    }
    
    .phone-menu-categories-title {
      text-transform: uppercase;
      font-size: 20px;
      font-weight: bold;
      padding: 12px 20px 12px 20px;
      cursor: pointer;
      border-bottom: 0.5px solid silver;
      margin-left: 20px;
      opacity: 0.6;
      display: none;
    }
  
    .phone-menu-projects {
      list-style: none;
      margin: 0;
      padding: 0;
      
    }
    .phone-menu-project-item {
      font-size: 20px;
      margin: 0;
      padding: 10px;
      cursor: pointer;
      padding: 12px 20px 12px 20px;
      margin-left: 20px;
      transition: opacity 0.25s ease;
      text-transform: uppercase;
      border-bottom: 0.5px solid silver;
      font-weight: 300;
      opacity: 0.8;
    }
    .phone-menu-project-item:hover {
      opacity: 1;
    }
  
    /* Example styling for error messages if you have them. */
    .status-message.error {
      color: red;
      margin: 10px 0;
    }
  }