/* Hide on desktop by default */
.phone-header {
    display: none;
  }
  
  @media (max-width: 768px) {
    .phone-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;  /* adjust as needed */
      padding-top:30px;
      padding-left:25px;
      background: black;
      margin-bottom: 0;
      z-index: 10000;
      position: relative;  /* remains in normal flow */
    }
    
    .phone-header-logo .logo {
      width: 100px; /* adjust as needed */
      height: auto;
    }
    
    /* Fixed button container ensures consistent positioning */
    .phone-header-menu-button {
      background: none;
      border: none;
      cursor: pointer;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px; /* fixed width */
    }
    
    .phone-header-menu-button:focus {
      outline: none;
    }
    
    /* Button text styling */
    .button-text {
      font-size: 26px; /* bigger text */
      display: inline-block;
    }
    
    /* Common chevron icon styling: both icons share these dimensions */
    .chevron-icon {
      width: 32px;
      height: 32px;
      stroke: currentColor;
      display: inline-block;
      flex-shrink: 0;
    }
    
    /* In MENU state, the chevron appears after the text with left margin */
    .menu-chevron {
      margin-left: 0px;
      transform: rotate(-90deg);

    }
    
    /* In BACK state, the chevron is rotated -90° (90° counter-clockwise) and appears before the text with right margin */
    .back-chevron {
      transform: rotate(90deg);
      margin-right: 2px;
    }
  }