/* src/App.css */

/* 
   Desktop baseline (>= 768px).
   We'll add media queries at the bottom for phone layout (max-width: 768px).
*/

.app-wrapper {
  margin: 75px 75px 0px 75px;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 75px);
  overflow: hidden; 
  min-width: 1124px; 
}

.main-content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.left-column {
  width: 135px;
  margin-right: 40px;
  flex-shrink: 0;
}

.right-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* The container for the ProjectsGrid, which scrolls internally. */
.projects-scroll-container {
  flex: 1;
  overflow-y: auto; 
  -ms-overflow-style: none; /* hide scrollbar in IE/Edge */
  scrollbar-width: none;    /* hide scrollbar in Firefox */
}
.projects-scroll-container::-webkit-scrollbar {
  display: none;
}


/* 
   ============= MEDIA QUERIES FOR PHONE =============
   We consider max-width: 768px as "phone" 
   (you can tweak to 600px or something else).
*/
@media (max-width: 768px) {

  /* Let’s hide the left column (sidebar) entirely on phone. */
  .left-column {
    display: none;
  }

  /* Let’s reduce margins so we get edge-to-edge on phone. */
  .app-wrapper {
    margin: 0; 
    height: 100vh;
    min-width: 0; /* Let phone widths go smaller. */
  }

  /* .main-content becomes a single column. If you want the top navbar 
     to remain at top and grid below it, we can do normal flow. */
  .main-content {
    flex-direction: column;
  }

  /* For the phone layout, you might want the grid items to become 1-per-row. 
     We'll handle that with a media query in ProjectsGrid.css. */

}