/* Disable text selection globally */
* {
  user-select: none;
  -webkit-user-select: none; /* Chrome, Safari, Opera */
  -moz-user-select: none;    /* Firefox */
  -ms-user-select: none;     /* Internet Explorer/Edge */
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; 
  background-color: black;
  color: white;
  font-family: Helvetica, Arial, sans-serif;
}