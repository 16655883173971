/* src/components/ProjectsGrid.css */

/* Desktop (>=768px) default: 3 columns. */
.projects-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0; 
}

.intro-item {
  display: none;
}

.grid-item {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.grid-item:hover {
  transform: scale(0.995);
}

/* Updated grid-image-wrapper for pre-sizing using aspect-ratio */
.grid-image-wrapper {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  background-color: #000;
}

.grid-image {
  display: block;
  width: 100%;
  height: 100%;     
  object-fit: cover; 
}

.grid-image-fallback {
  width: 100%;
  height: 0;
  padding-bottom: 66.67%;
  background-color: #000;
}

.grid-title {
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
  text-align: right;
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  transition: opacity 0.2s linear;
  opacity: 0.6;
  z-index: 2;
}

.grid-title:hover {
  opacity: 1;
}

.grid-title-line {
  display: inline-block;
  background-color: black;
  opacity: 1;
  line-height: 1;
}

/* 
   ============= PHONE MEDIA QUERY =============
   On phone (max-width: 768px), switch to 1 column. 
*/
@media (max-width: 768px) {
  .projects-grid {
    grid-template-columns: 1fr; /* Single column. */
    gap: 0; 
    padding-bottom: 0px; 
  }

  /* Possibly increase each item’s bottom padding so it’s bigger. 
     Or keep it the same. For a “list” vibe, this might be good. 
  */
  .grid-item {
    margin-bottom: 0px; /* optional spacing between items */
  }

  .grid-item:hover {
    transform: none;
  }
  .grid-image-wrapper {
    position: relative;
    width: 100%;
    height: 00% ;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
  }
  
  .grid-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; /* 16:9 Aspect Ratio (9/16 = 0.5625) x2 */
    object-fit: cover; /* Ensures the image covers the container while maintaining aspect ratio */
  }
  
  .grid-image-fallback {
    width: 100%;
    height: 0;
    padding-bottom: 0;
    background-color: #000;
  }
  
  .grid-title {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, 0%);
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    transition: none;
    opacity: 0.6;
    z-index: 2;
  }

  .grid-title:hover {
    opacity: 0.6;
  }
  
  .grid-title-line {
    display: inline-block;
    background-color: black;
    opacity: 1;
    line-height: 1;
  }
  
  .intro-item {
    display: block;
    /* optional spacing/padding if you want: */
    padding: 16px;
  }

  .intro-content {
    text-align: center;
    font-family: Times, serif;
    margin-bottom: 25px;
  }

  .intro-text, 
  .intro-links {
    color: #c0c0c0;
    margin: 0; /* remove default p margin */
  }

  .intro-white {
    color: white;
    font-weight: bold;
  }

  .intro-grey {
    color: #c0c0c0;
  }

  .intro-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    margin-top: 8px;
  }

  .intro-links a {
    color: #c0c0c0;
    font-weight: bold;
    text-decoration: none; /* Remove underline */
  }

  .intro-links span {
    color: #c0c0c0;
    text-decoration: none;
  }
}